import React from "react";
import {
	Box,
	Grid,
	Button,
	TextField,
	Typography,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import AutocompleteServicesCodes from "../../../../../components/autocomplete/AutocompleteServicesCodes";
import agent from "../../../../../agent/agent";

class DialogForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			item: null,
			isOpen: false,
			isLoading: true,
		};
	}

	open = async ({ item, onSubmit }) => {
		const matching = await agent.get(`/codes?search=${ item.matching_phrase }`).then((res) => {
			return (res.data?.data || []).find((t) => Boolean(String(t.name) === String(item.matching_phrase))) || null;
		}).catch(() => {
			return null
		});

		this.setState({
			item: {
				...item,
				matching,
			},
			isOpen: true,
			onSubmit,
		});
	}
	close = () => {
		this.setState({
			item: null,
			isOpen: false,
			isLoading: true,
		})
	}

	submitItem = async () => {
		this.state.onSubmit(this.state.item, true, true)
	}
	changeItem  = ({ target }) => {
		const { name, value } = target;
		let item = {...this.state.item};
		item[name] = value;
		this.setState({ item });
	}
	changeMatching = (event, value) => {
		this.changeItem({
			target: {
				name: "matching",
				value,
			}
		});
	}

	render () {
		const {
			item,
			isOpen
		} = this.state;
		const {classes} = this.props;

		if (!isOpen) {
			return null
		}
		return (
			<Dialog
				fullWidth
				open={isOpen}
				maxWidth="sm"
				onClose={this.close}
			>
				<DialogTitle className={classes.dialogTitle}>
					<Typography variant="h3">Редактирование справочника</Typography>
				</DialogTitle>
				<DialogContent>
					<Box pt={2}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									value={item.phrase}
									name="phrase"
									size="small"
									label="Поисковое слово"
									placeholder="Поисковое слово"
									fullWidth
									onChange={this.changeItem}
								/>
							</Grid>
							<Grid item xs={12}>
								<AutocompleteServicesCodes
									value={item.matching}
									name="matching"
									size="small"
									label="Результат для поиска"
									placeholder="Результат для поиска"
									fullWidth
									onChange={this.changeMatching}
								/>
							</Grid>
						</Grid>
					</Box>
				</DialogContent>
				<DialogActions>
					<Grid justifyContent="flex-end" container px={2} spacing={1}>
						<Grid item>
							<Button
								size="small"
								variant="outlined"
								onClick={this.close}
							>Закрыть</Button>
						</Grid>
						<Grid item>
							<Button
								size="small"
								variant="contained"
								onClick={this.submitItem}
							>Сохранить</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}

const styles = {
	dialogTitle: {
		background: "#FF3F3F",
		"& .MuiTypography-h3": {
			color: "white",
			textAlign: "left",
		}
	}
};
DialogForm = withStyles(styles)(DialogForm);

export default DialogForm

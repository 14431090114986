import React, {Component} from "react";
import {
	Backdrop,
	CircularProgress
} from "@mui/material";
import {
	Table,
	Filter,
	DialogForm
} from "./components";
import {
	DialogConfirmation
} from "../../../components";
import {
	Notification,
	notificationTypes
} from "../../../common/Notification";
import queryString from "query-string";
import agent from "../../../agent/agent";

const initForm = {
	searchPhrase: "",
	matchingPhrase: "",
};
const initFilter = {
	phrase: "",
	matching_phrase: "",
};

class Dictionary extends Component {
	constructor(props) {
		super(props);

		const searchString = String(window.location.search);
		const searchParams = queryString.parse(searchString);

		this.state = {
			items: [],

			form: {...initForm},
			filter: {
				...initFilter,
				phrase: searchParams?.phrase || "",
				matching_phrase: searchParams?.matching_phrase || "",
			},

			page: Number.parseFloat(searchParams?.page || 1),
			limit: 20,
			totalPage: 1,

			isLoading: true,
			isBackdrop: false,
		};
		this.refDialogForm =React.createRef();
		this.refDialogConfirmation =React.createRef();
	}

	componentDidMount = async () => {
		await this.getItems();
	}

	getItems = async () => {
		await this.setState({ isLoading: true });
		const filter = this.getFilter();
		const res = await agent.get(`/dictionary/all${ filter }`).then((res) => {
			return res.data
		}).catch(() => {
			return {}
		});
		await this.setState({
			items: res?.data || [],
			isLoading: false,
			totalPage: Math.ceil(res?.total / this.state.limit)
		});
		await this.setFilterSearch();
	};
	changePage = async (page) => {
		await this.setState({ page });
		await this.getItems();
	}

	createItem = async () => {
		await this.setState({ isBackdrop: true });
		const { form } = this.state;

		const body = {
			searchPhrase: form.searchPhrase,
			matchingPhrase: form?.matchingPhrase?.name,
			codeId: form?.matchingPhrase?.id
		}

		const res = await agent.post('/dictionary/set-matching', body).then((res) => {
			return res?.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (res?.error) {
			await this.setState({ isBackdrop: false });
			Notification({
				type: notificationTypes.error,
				message: "Возникла ошибки, повторите попытку позднее"
			})
			return
		}
		await this.getItems();
		await this.setState({ isBackdrop: false, form: {...initForm} });
		Notification({
			type: notificationTypes.success,
			message: "Успешно"
		});
	};
	editItem = async (item, isSend, isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: "Вы действительно хотите изменить справочник? Будут изменены все заявки.",
				acceptButtonAction: this.editItem.bind(this, item, false, true)
			})
			return
		}
		if (!isSend) {
			this.refDialogForm.current.open({
				item: item,
				onSubmit: this.editItem.bind(this)
			});
			return
		}

		await this.setState({ isBackdrop: true });
		const response = await agent.patch(`/dictionary/${ item.id }`, {
			phrase: item.phrase,
			matching_phrase: item?.matching?.name || item?.matching_phrase
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response || true}
		})
		if (response?.error) {
			await this.setState({ isBackdrop: false });
			Notification({
				type: notificationTypes.error,
				message: "Возникла ошибки, повторите попытку позднее"
			})
			return
		}
		await this.getItems();
		this.refDialogForm.current.close();
		await this.setState({ isBackdrop: false });
	}
	changeItem = async ({ target }) => {
		const { name, value } = target;
		let _form = {...this.state.form};
		_form[name] = value;
		this.setState({ form: _form });
	};
	removeItem = async (item, isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Вы действительно хотите удалить связь "${item?.phrase} - ${item?.matching_phrase}"?`,
				acceptButtonTitle: "Да, удалить",
				acceptButtonAction: this.removeItem.bind(null, item, true)
			})
			return
		}
		await this.setState({ isBackdrop: true });
		const res = await agent.delete(`/dictionary/delete-matching/?id=${ item.id }`).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: true}
		});
		if (res?.error) {
			await this.setState({ isBackdrop: false });
			Notification({
				type: notificationTypes.error,
				message: "Ошибка удаления связи, повторите попытку позднее."
			})
			return
		}
		await this.getItems();
		await this.setState({ isBackdrop: false });
		Notification({
			type: notificationTypes.success,
			message: "Связь успешно удалена"
		})
	};

	getFilter = () => {
		const { page, limit, filter } = this.state;

		let _str = [
			`page=${ page }`,
			`limit=${ limit }`,
		];
		Object.keys(filter).map((filterKey) => {
			if (filter[filterKey]) {
				_str.push(`${ filterKey }=${ filter[filterKey] }`);
			}
		});

		return `?${ _str.join('&') }`
	};
	resetFilter = async () => {
		await this.setState({
			filter: {...initFilter},
			page: 1,
		});
		await this.getItems();
	}
	changeFilter = (filter) => {
		this.setState({
			filter,
			page: 1
		});
	}
	setFilterSearch = () => {
		const { filter, page } = this.state;
		const _filter = [
			Boolean(page > 1) &&`page=${ page }`,
			Boolean(filter.phrase) && `phrase=${ filter.phrase }`,
			Boolean(filter.matching_phrase) && `phrase=${ filter.matching_phrase }`,
		].filter((t) => !!t).join('&');
		window.history.replaceState(null, null, ['/dictionary', _filter].filter((t) => !!t).join('?'))
	}

	render() {
		const {
			form,
			page,
			items,
			filter,
			totalPage,
			isLoading,
			isBackdrop
		} = this.state;

		return (
			<>

				<Filter
					filter={filter}
					onSubmit={this.getItems}
					onReset={this.resetFilter}
					onChange={this.changeFilter}
				/>

				<Table
					page={page}
					items={items}
					total={totalPage}
					isLoading={isLoading}

					form={form}
					onEditItem={this.editItem}
					onChangeForm={this.changeItem}
					onCreateItem={this.createItem}
					onRemoveItem={this.removeItem}

					onChangePage={this.changePage}
				/>

				<Backdrop open={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
				<DialogForm ref={this.refDialogForm}/>
				<DialogConfirmation ref={this.refDialogConfirmation}/>

			</>
		);
	}
}

export default Dictionary

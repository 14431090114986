import React from "react";
import {
	Box,
	Grid,
	Button,
	Divider,
	Typography,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	palette
} from "../../../../../theme/common";
import {
	PhotoImage
} from "../DialogCreateWork";
import {
	AutocompleteServicesCodes
} from "../../../../../components"

class DialogWorkManager extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			form: {},
			isOpen: false,
			isRecommendation: false,

			stepForm: "works",

			onSubmit: null,
			onDeleteWorkFile: null,
		};
	}

	open = ({initForm: editInitForm, onSubmit, onDeleteWorkFile, isRecommendation}) => {
		this.setState({
			form: {...editInitForm},
			isOpen: true,
			isRecommendation,
			onSubmit,
			onDeleteWorkFile
		})
	}
	close = () => {
		this.setState({
			form: {},
			isOpen: false,
			isRecommendation: false,

			stepForm: "works",

			onSubmit: null,
			onDeleteWorkFile: null,
		})
	}

	changeForm = (form) => {
		this.setState({ form });
	}
	changeFormItems = (index, event, value) => {
		let form = {...this.state.form};
		form.items[index]['code'] = value || null;
		form.items[index]['code_id'] = value?.id || null;
		this.setState({ form });
	}
	changeStepForm = () => {
		this.submitForm();
	}

	removeImage = (fileId, callback) => {
		const serviceId = this.state?.form?.id || 0;
		this.state.onDeleteWorkFile(fileId, serviceId, callback);
	}

	submitForm = () => {
		const { form } = this.state;

		const body = {
			...form
		};
		this.state.onSubmit(body, true);
	}

	_title = () => {
		const { isRecommendation } = this.state;
		if (isRecommendation) {
			return 'Редактирование перечня рекомендация'
		}
		return 'Редактирование перечня работ'
	}

	render() {
		const {
			classes
		} = this.props;
		const {
			form,
			isOpen,
			stepForm
		} = this.state;

		return (
			<Dialog
				open={isOpen}
				fullWidth
				className={classes.dialog}
			>
				<DialogTitle className={classes.dialogTitle}>
					<Typography variant="h3">{this._title()}</Typography>
				</DialogTitle>

				<DialogContent >
					<Box mt={2}/>

					<VisibleContent visible={Boolean(stepForm === 'works')}>
						<Box className={classes.section}>
							<Typography className={classes.sectionTitle}>Перечень</Typography>
							<Grid container spacing={2}>
								{(form?.items || []).map((_item, index) => <WorkRowItem
									item={_item}
									onChange={this.changeFormItems.bind(this, index)}
								/>)}
							</Grid>
						</Box>
					</VisibleContent>

					<VisibleContent visible={Boolean(stepForm === 'files')}>
						<Box className={classes.section}>
							<PhotoImage
								form={form}
								onChange={this.changeForm}
								onDelete={this.removeImage}
							/>
						</Box>
					</VisibleContent>

				</DialogContent>

				<DialogActions>
					<Box px={2} pb={1} sx={{ flex: 1 }}>
						<Grid container spacing={2} justifyContent="space-between">
							<Grid item>
								<Button
									size="small"
									variant="outlined"
									className={classes.dialogActionsButton}
									onClick={this.close}
								>
									Закрыть
								</Button>
							</Grid>
							<Grid item>
								<Button
									size="small"
									variant="contained"
									className={classes.dialogActionsButton}
									onClick={this.changeStepForm.bind(null, 'next')}
								>Сохранить</Button>
							</Grid>
						</Grid>
					</Box>
				</DialogActions>
			</Dialog>
		)
	}
};
const WorkRowItem = (props) => {
	const {
		item,
		onChange
	} = props;
	const isError = !Boolean(item?.code);

	return (
		<Grid item xs={12}>
			<Box
				px={2}
				py={1}
				bgcolor="#F4F6F8"
				borderRadius={2}
				border="1px solid #F4F6F8"
				borderColor={Boolean(isError) ? palette.error.main : '#F4F6F8'}
			>
				<Grid container spacing={2} wrap="nowrap">
					<Grid item xs={5}>
						<Typography variant="caption" textAlign="left" justifyContent="flex-start" mb={1}>
							Комментарий от механика
						</Typography>
						<Typography variant="h4">{ item?.description }</Typography>
					</Grid>
					<Grid item>
						<Divider orientation="vertical" sx={{ borderColor: "#152435", opacity: 0.2 }}/>
					</Grid>
					<Grid item xs={7}>
						<Typography variant="caption" textAlign="left" justifyContent="flex-start" mb={1}>
							Нормативная работа
						</Typography>
						<AutocompleteServicesCodes
							value={item.code}
							key={item.description}
							onChange={onChange}
						/>
					</Grid>
				</Grid>
			</Box>
		</Grid>
	)
};
const VisibleContent = ({ visible, children }) => {
	if (!visible) return null
	return children
}

const styles = {
	dialog: {
		"& .MuiPaper-root": {
			maxWidth: 1200,
			overflow: "initial"
		}
	},
	dialogTitle: {
		background: palette.primary.main,
		"& .MuiTypography-root": {
			textAlign: 'left',
			color: palette.primary.contrastText,
		}
	},

	section: {},
	sectionTitle: {
		marginBottom: 16,

		fontSize: 22,
		lineHeight: "24px",
		color: "#152435",
		fontWeight: "600",

		"@media(max-width: 1023px)": {
			marginBottom: 8,
			fontSize: 16,
			lineHeight: "18px",
		},
	},
};
DialogWorkManager = withStyles(styles)(DialogWorkManager);

export default DialogWorkManager

import React from "react";
import agent from "../../agent/agent";
import {Autocomplete, TextField, FormHelperText} from "@mui/material";
import {makeStyles} from "@mui/styles";

let timeoutGetResult = null;
const AutocompleteService = (props) => {
	const {
		value,
		phrase,
		onChange,
		// disabled,

		...otherProps
	} = props;
	const classes = useStyles();
	console.log('props: ', props);

	const [page, setPage] = React.useState(1);
	const [limitPage, setLimitPage] = React.useState(1);

	const [search, setSearch] = React.useState('');
	const [options, setOptions] = React.useState([]);
	const [isLoading, setLoading] = React.useState(false);

	const handleGetOptions = async (_search) => {
		const params = [
			`search=${ _search }`,
			`page=${ page }`,
			'limit=20'
		].join('&');
		const res = await agent(`/codes?${ params }`).then((res) => {
			return res?.data
		}).catch(() => {
			return []
		});
		setLimitPage(res?.last_page || 1);
		setOptions([...options, ...(res?.data || [])]);
		setLoading(false)
	}
	const handleLoadMore = async () => {
		let newPage = page + 1;
		if (newPage > limitPage) {
			return
		}
		await setPage(newPage);
		await handleGetOptions(search);
	};
	const handleChangeSearch = (event, _search) => {
		if (event?.type === 'blur') {
			setOptions([])
			return
		}

		clearTimeout(timeoutGetResult);
		setPage(1);
		setOptions([]);
		setLoading(String(_search || '').length >= 2)
		setSearch(_search);
		timeoutGetResult = setTimeout(async () => {
			await handleGetOptions(_search);
		}, 1500)
	}

	const _getOptionLabel = (option) => {
		return option?.name || ''
	}
	const eventListBoxPropsOnScroll = async (event) => {
		const listboxNode = event.currentTarget;
		if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
			await handleLoadMore();
		}
	}

	return (
		<>
			<Autocomplete
				{...otherProps}

				value={value}

				options={options}
				variant="outlined"
				fullWidth
				autoComplete
				disablePortal={true}
				loading={isLoading}
				limitTags={1}
				noOptionsText="По вашему запросу ничего не найдено"

				renderInput={(params) => <TextField
					{...params}
					fullWidth
					size="small"
				/>}

				filterOptions={(options) => [...options]}
				getOptionLabel={_getOptionLabel}
				onInputChange={handleChangeSearch}

				classes={{
					option: classes.autocomplete
				}}

				onChange={onChange}

				ListboxProps={{
					role: "list-box",
					onScroll: eventListBoxPropsOnScroll
				}}
			/>
			{Boolean(otherProps.disabled) && (
				<FormHelperText sx={{ fontSize: 12, lineHeight: 1.1 }}>
					Чтобы изменить "Нормативную работу" перейдите в раздел <a href={`/dictionary?phrase=${ phrase || '' }&matching_phrase=${value?.name || ''}`} target="_blank" style={{ display: 'contents', color: '#ff3f3f', textDecoration: 'underline' }}>справочники</a> измените связь.
				</FormHelperText>
			)}
		</>
	)
};

const useStyles = makeStyles(() => ({
	autocomplete: {
		color: "#152435",
		fontSize: "16px",
		lineHeight: "20px",
	}
}));

export default AutocompleteService

import agent from "../agent/agent";

const getTricepsWorkCodes = async (search = '') => {
	if (search.length < 1) {
		return null
	}
	return await agent.get(`/codes?search=${ search }`).then((res) => {
		return res?.data?.data?.[0]?.id || null
	}).catch(() => {
		return null
	})
}
const getTricepsWorkDictionary = async (search = '') => {
	if (search.length < 1) {
		return null
	}
	return await agent.get(`/dictionary?search=${ search }`).then((res) => {
		return res?.data?.data?.[0]?.id || null
	}).catch(() => {
		return null
	})
}
const getTricepsWorkServices = async () => {}

const getApplicationItemsList = async (items = [], userRole = '') => {

	let _items = [];
	for (const item of items) {
		let code_id = item?.code_id || item?.code?.id || null;
		if (!code_id && userRole === 'mechanic') {
			code_id = await getTricepsWorkCodes(item?.description || '') || null
		}
		_items.push({
			...item,
			code_id
		});
	}

	return _items
};
const getApplicationItemsListDictionary = async (items = [], userRole = '') => {

	let _items = [];
	for (const item of items) {
		let code_id = item?.code_id || item?.code?.id || null;
		if (!code_id && userRole === 'mechanic') {
			code_id = await getTricepsWorkDictionary(item?.description || '') || null
		}
		_items.push({
			...item,
			code_id
		});
	}

	return _items
};

export {
	getTricepsWorkCodes,
	getTricepsWorkServices,
	getApplicationItemsList,
	getApplicationItemsListDictionary
}

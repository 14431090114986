import React, {Component} from "react";
import {Backdrop, Box, Button, CircularProgress, Grid} from "@mui/material";
import {withStyles} from "@mui/styles";
import {
	ApplicationInfo as ApplicationInfoComponent, DiagnosticsInfo,
	DialogCreateWork as DialogCreateWorkComponent,
	RecommendationList as RecommendationListComponent,
	UserInfo as UserInfoComponent,
	WorkList as WorkListComponent,
	TransportInfo as TransportInfoComponent,
	DialogWorkManager,
	DialogRecomendationManager
} from "./components";
import {
	DialogConfirmation,
	DialogImagesVideos,
	DialogImagesMessage
} from "../../../components";
import agent from "../../../agent/agent";
import {Notification, notificationTypes} from "../../../common/Notification";
import {clipboardWriteText} from "../../../common/Clipboard";
import optimizationImageVideo from "../../../common/OptimizationImageVideo";
import DialogCreateRecommendation from "./components/DialogCreateRecommendation";
import {getApplicationItemsListDictionary} from "../../../utils/standardTricepsWork";

class Application extends Component {
	constructor(props) {
		super(props);

		const isConsultant = Boolean(['admin', 'consultant'].includes(props?.userRole));

		this.state = {
			application: {},

			applicationId: props?.match?.params?.id || null,

			isConsultant,
			isLoad: true,
			isFail: false,
			isFilesLoading: true,
			isFullDisabled: false,
			isShowBackdrop: false,
			isAddClientButtonDisabled: true
		};

		this.refDialogCreateWork = React.createRef();
		this.refDialogWorkManager = React.createRef();
		this.refDialogConfirmation = React.createRef();
		this.refDialogImagesVideos = React.createRef();
		this.refDialogImagesMessage = React.createRef();
		this.refDialogCreateRecommendation = React.createRef();
		this.refDialogRecomendationManager = React.createRef();
		this.subscribeTimeout = null;
		this.subscribeTimeoutInterval = 5 * 1000;
	}

	componentDidMount = async () => {
		await this.getApplication();
		if (!this.state.application.client && this.state.application.tricepsBarcode) {
			setTimeout(() => {
				this.setState({
					isAddClientButtonDisabled: false,
				});
			}, 30000);
			this.subscribeTimeout = setTimeout(async () => {
				await this.subscribeLongPollAddClient();
			}, this.subscribeTimeoutInterval);
		}
	}

	componentWillUnmount = () => {
		clearTimeout(this.subscribeTimeout);
	};

	// Логика установки клиента на заказ
	longPollAddClient = async () => {
		const {
			application
		} = this.state;

		const response = await agent.get(`/orders/mechanic/by-barcode/${this.state.application.tricepsBarcode}`)
			.then(res => res.data.order)
			.catch(err => {
				return {error: err.response}
			});

		if (response.error) {
			return null;
		}

		if (response.client && response.clientId && !application.client && !application.clientId) {
			this.setState({application: response});

			clearTimeout(this.subscribeTimeout);

			Notification({
				message: "Клиент успешно найден",
				type: notificationTypes.success,
			});

			return null;
		}

		return null;
	};

	// Интервальный вызов функции установки клиента на заказ
	subscribeLongPollAddClient = async () => {
		await this.longPollAddClient();

		this.subscribeTimeout = setTimeout(async () => {
			await this.subscribeLongPollAddClient();
		}, this.subscribeTimeoutInterval);
	};

	getApplication = async () => {
		const {applicationId} = this.state;

		let response = await agent.get(`/orders/mechanic/${applicationId}`).then((res) => {
			return res.data?.order || null
		}).catch((err) => {
			return null
		})
		if (response === null) {
			this.setState({
				isFail: true,
				isLoad: false
			})

			return
		}

		response.orderServices = [...response.orderServices].sort((a, b) => {
			if (a.createdAt > b.createdAt) {
				return 1
			}
			if (a.createdAt < b.createdAt) {
				return -1
			}
			return 0
		})

		this.setState({
			order: response,
			isLoad: false
		})

		response.orderServices = await Promise.all(([...response.orderServices]).map(async (service) => {
			service.orderId = service.orderId || response.id;
			service.pictures = await Promise.all((service.pictures || []).map(async (file) => {
				const uri = [process.env.REACT_APP_HOST_API, '/static/service/', file.name].join("");
				return {
					isNew: false,
					fileUrl: uri,
					mimetype: file.mimetype,
					// file: await getFileFromUrl( uri, file.name, file.mimetype ),
					fileId: file.id
				};
			}));

			return service
		}));
		response.recommendationsForClient = (await Promise.all(([...response.recommendationsForClient])
			.map(async (recommendation) => {
				recommendation.pictures = await Promise.all((recommendation.pictures || []).map(async (file) => {
					const uri = [process.env.REACT_APP_HOST_API, '/static/recommendation/', file.name].join("");
					return {
						isNew: false,
						fileUrl: uri,
						mimetype: file.mimetype,
						fileId: file.id,
					};
				}));
				recommendation.items = [...(recommendation.items || [])].sort((a, b) => {
					if (a.id > b.id) {
						return 1
					}
					if (a.id < b.id) {
						return -1
					}
					return 0
				})
				return recommendation
			})))
			.sort((a, b) => {
				if (a.id > b.id) {
					return 1
				}
				if (a.id < b.id) {
					return -1
				}
				return 0
			})

		this.setState({
			application: response,
			isFilesLoading: false,
			isFullDisabled: Boolean(response?.status === "EXECUTED" || response?.status === "HOLD")
		})
	}

	addClient = async () => {
		const {
			application
		} = this.state;

		this.setState({isShowBackdrop: true});

		const response = await agent.put(`/orders/mechanic/${this.state.applicationId}/add-client`)
			.then(res => res.data.order)
			.catch(err => {
				return {error: err.response}
			});

		if (response.error) {
			this.setState({
				isShowBackdrop: false,
			});

			Notification({
				message: response.error?.data?.details || "Ошибка при поиске клиента",
				type: notificationTypes.error,
			});

			return null;
		}

		if (response.client && response.clientId && !application.client && !application.clientId) {
			this.setState({
				application: response,
				isShowBackdrop: false,
				isAddClientButtonDisabled: false,
			});

			clearTimeout(this.subscribeTimeout);

			Notification({
				message: "Клиент успешно найден",
				type: notificationTypes.success,
			});

			return null;
		}
	};

	// Логика синхронизации заказа с трицесом
	orderSynchronization = async (isConfirm = false) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Вы точно хотите синхронизировать заказ с трицепсом?`,
				acceptButtonTitle: "Да, синхронизировать",
				acceptButtonAction: this.orderSynchronization.bind(this, true),
			});

			return null;
		}

		this.setState({isShowBackdrop: true});

		const response = await agent.put(`orders/mechanic/${this.state.applicationId}/triceps/synchronize`)
			.then((res) => res.data)
			.catch((err) => {
				return {error: err.response}
			});

		if (response.error) {
			this.setState({isShowBackdrop: false});
			Notification({
				message: response.error?.data?.details || "Ошибка сервера",
				type: notificationTypes.error,
			});

			return;
		}

		await this.getApplication();
		this.setState({isShowBackdrop: false});
		Notification({
			message: 'Синхронизация успешно прошла',
			type: notificationTypes.success,
		});
	};

	// Логика работы по работам
	createServices = async (form = null) => {
		if (form === null) {
			this.refDialogCreateWork.current.open({
				onSubmit: this.createServices.bind(this)
			})

			return
		}
		this.setState({isShowBackdrop: true})

		let _bodyCreate = JSON.parse(JSON.stringify(form));
		_bodyCreate.items = [..._bodyCreate.items].filter((t) => Boolean(t?.description || (t?.images || []).length > 0)).map((t) => {
			delete t.images;
			return t
		})
		_bodyCreate.items = await getApplicationItemsListDictionary(_bodyCreate.items || [], this.props.userRole);

		// Добавление параметров в зявку
		const serviceOrder = await agent.post(`/orders/mechanic/${this.state.applicationId}/services`, {
			items: _bodyCreate.items,
			"description": _bodyCreate.applicationDescription,
			"cost": Number.parseFloat(_bodyCreate.applicationPrice),
			"estimates": (_bodyCreate.partsList || []).map((t) => {
				return {
					"description": t.name,
					"partsCost": Number.parseFloat(t.price)
				}
			})
		}).then((res) => {
			return res.data?.orderService
		}).catch((err) => {
			return {error: err.response}
		})
		if (serviceOrder.error) {
			this.setState({isShowBackdrop: false})

			Notification({
				message: serviceOrder.error?.data?.details || "Ошибка сервера",
				type: notificationTypes.error
			})

			return
		}

		let body = {
			id: serviceOrder?.id,
			orderId: this.state.applicationId,
			"items": form.items || [],
			"files": form.files || [],
			"description": form.applicationDescription,
			"cost": Number.parseFloat(form.applicationPrice),
			"estimates": (form.partsList || []).map((t) => {
				return {
					"description": t.name,
					"partsCost": Number.parseFloat(t.price)
				}
			})
		}
		await this.editServices(body)
	}
	editServices = async (service) => {
		this.setState({isShowBackdrop: true})

		let body = {...service}
		body.userId = this.props.user?.id;
		delete body.createdAt;
		delete body.updatedAt;
		delete body.id;
		delete body.orderId;

		body.items = [...(body.items || [])].filter((t) => Boolean(t?.description || (t?.images || []).length > 0));
		body.items = await getApplicationItemsListDictionary(body.items || [], this.props.userRole);

		// Логика для каждой работы отдельно
		let newServiceItems = [];
		let errorsCreateMatching = [];
		for (const item of (body?.items || [])) {
			let _imagesIds = [...(item?.images || [])].filter((t) => !Boolean(t.isNew)).map((t) => {
				return {id: t?.fileId || t?.id}
			});
			const _images = (await optimizationImageVideo(item?.images || [])).filter((t) => t.isNew);
			await Promise.all(_images.map(async (_image) => {
				const formData = new FormData();
				formData.append("file", _image.file);
				const fileId = await agent.post(`/orders/mechanic/${service.orderId}/services/${service.id}/upload`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}).then((res) => {
					return res.data?.fileId || null
				}).catch((err) => {
					return null
				})
				if (!fileId) {
					Notification({
						message: "Ошибка добавление одно из файлов",
						type: notificationTypes.error
					})
				}
				_imagesIds.push({
					id: fileId
				})
			}));

			let matchingId = null;
			if (item?.is_create_connection) {
				const body = {
					searchPhrase: item?.description,
					matchingPhrase: item?.code?.name,
					codeId: item?.code?.id
				};
				matchingId = await agent.post('/dictionary/set-matching', body).then((res) => res?.data?.id).catch(() => null);
				if (!matchingId) {
					errorsCreateMatching.push(`Ошибка добавления пары.<br/>Интерпретация: ${item.description};<br/>Нормативная работа: ${item?.code?.name};`)
				}
			} else {
				matchingId = item.code_id
			}

			newServiceItems.push({
				...item,
				images: _imagesIds,
				code_id: matchingId
			})
		}
		body.items = newServiceItems;

		const response = await agent.put(`/orders/mechanic/${service.orderId}/services/${service.id}`, body).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		if (response.error) {
			this.setState({isShowBackdrop: false})

			Notification({
				message: response.error?.data?.details || "Ошибка сервера",
				type: notificationTypes.error
			})

			return
		}

		// Логика для общих файлов работы
		if (body.files && body.files.length > 0) {
			const files = (await optimizationImageVideo(body.files)).filter((t) => t.isNew);
			await Promise.all(files.map(async (file) => {
				const formData = new FormData();
				formData.append("file", file.file);
				const response = await agent.post(`/orders/mechanic/${service.orderId}/services/${service.id}/upload`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}).then((res) => {
					return res.data
				}).catch((err) => {
					return {error: err.response}
				})
			}));
		}


		await this.getApplication();
		this.refDialogCreateWork.current.close();
		this.refDialogWorkManager.current.close();
		this.refDialogRecomendationManager.current.close();
		this.setState({isShowBackdrop: false});


		if (errorsCreateMatching.length > 0) {
			this.refDialogConfirmation.current.onOpen({
				title: "Уведомление",
				message: `Работа успешно обновлена.<br/><br/>Но часть работ не была обновлена, потому что содержит дубли записей справочника.<br/><br/>${errorsCreateMatching.join('<br/><br/>')}`,
				hideCancel: true
			})
			return
		}

		Notification({
			message: "Работа успешно обновлена",
			type: notificationTypes.success
		})
	}
	openEditServices = async (form = {}, isSave = false) => {
		if (!isSave) {
			const initForm = {
				id: form.id,
				orderId: form.orderId,
				items: form?.items || [],
				files: form.pictures,
				applicationDescription: form.description,
				applicationPrice: String(form.cost),
				partsList: (form.estimate || []).map((t) => {
					return {name: t.description, price: String(t.partsCost)}
				}),
			}

			const { userRole } = this.props;
			const isAdminConsultant = Boolean(userRole === "consultant" || userRole === "admin");
			if (isAdminConsultant) {
				this.refDialogRecomendationManager.current.open({
					initForm: {...initForm},
					isRecommendation: false,
					onSubmit: this.openEditServices.bind(this),
					onDeleteWorkFile: this.deleteWorkFile.bind(this),
				})
				return
			}

			this.refDialogCreateWork.current.open({
				initForm: {...initForm},
				onSubmit: this.openEditServices.bind(this),
				onDeleteWorkFile: this.deleteWorkFile.bind(this),
			})

			return
		}

		let body = {
			id: form.id,
			orderId: form.orderId,
			"items": form.items || [],
			"files": form.files || [],
			"description": form.applicationDescription,
			"cost": Number.parseFloat(form.applicationPrice),
			"estimates": (form.partsList || []).map((t) => {
				return {
					"description": t.name,
					"partsCost": Number.parseFloat(t.price)
				}
			})
		}
		await this.editServices(body)
	}
	deleteWorkFile = async (fileId, serviceId, callback, isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Вы точно хотите удалить файл?`,
				acceptButtonTitle: "Да, удалить",
				acceptButtonAction: this.deleteWorkFile.bind(this, fileId, serviceId, callback, true),
			});

			return null;
		}

		this.setState({isShowBackdrop: true})

		const response = await agent.delete(`/orders/mechanic/${this.state.applicationId}/services/${serviceId}/upload/${fileId}`)
			.then(res => res.data)
			.catch(err => {
				return {error: err.response}
			});

		if (response.error) {
			this.setState({isShowBackdrop: false})

			Notification({
				message: response.error?.data?.details || "Ошибка сервера",
				type: notificationTypes.error
			});

			return null;
		}

		await callback();
		await this.getApplication();
		this.setState({isShowBackdrop: false})
		Notification({
			message: "Файл успешно удален",
			type: notificationTypes.success
		});
	};
	deleteWork = async (serviceId, isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Вы точно хотите удалить работу?`,
				acceptButtonTitle: "Да, удалить",
				acceptButtonAction: this.deleteWork.bind(this, serviceId, true),
			});

			return null;
		}

		this.setState({isShowBackdrop: true});

		const response = await agent.delete(`/orders/mechanic/${this.state.applicationId}/services/${serviceId}`)
			.then(res => res.data)
			.catch(err => {
				return {
					error: err.response,
				};
			});

		if (response.error) {
			this.setState({isShowBackdrop: false})

			Notification({
				message: response.error?.data?.details || "Ошибка сервера",
				type: notificationTypes.error
			});

			return null;
		}

		await this.getApplication();
		this.setState({isShowBackdrop: false})
		Notification({
			message: "Работа успешно удалена",
			type: notificationTypes.success
		});
	};

	// Ремондации
	createRecommendationV2 = async (form) => {
		if (form === null) {
			this.refDialogCreateWork.current.open({
				onSubmit: this.createRecommendationV2.bind(this),
				isRecommendation: true
			})
			return
		}

		this.setState({isShowBackdrop: true});
		let _bodyCreate = JSON.parse(JSON.stringify(form));
		_bodyCreate.items = [..._bodyCreate.items]
			.filter((t) => {
				return Boolean(t?.description || (t?.images || []).length > 0)
			})
			.map((t) => {
				delete t.images;
				return t
			})
		_bodyCreate.items = await getApplicationItemsListDictionary(_bodyCreate.items || [], this.props.userRole);
		_bodyCreate.admin_note = _bodyCreate.items.map((t) => "").join("<br>");
		const responseCreate = await agent.post(`/orders/mechanic/${this.state.applicationId}/recommendations`, {
			files: [],
			items: [..._bodyCreate.items],
			"admin_note": _bodyCreate?.admin_note || "",
			"value": _bodyCreate.applicationDescription
		}).then((res) => {
			return res.data?.recommendationForClient
		}).catch((err) => {
			return {error: err.response}
		})
		if (responseCreate.error) {
			this.setState({isShowBackdrop: false})
			Notification({
				message: responseCreate.error?.data?.details || "Ошибка сервера",
				type: notificationTypes.error
			})

			return
		}

		let body = {
			id: responseCreate?.id,
			orderId: this.state.applicationId,
			"items": (form.items || []).filter((t) => {
				return Boolean(t?.description || (t?.images || []).length > 0)
			}),
			"files": form.files || [],
			"value": form.applicationDescription,
			"admin_note": _bodyCreate?.admin_note || ""
		}
		await this.editRecommendationV2(body)
	}
	editRecommendationV2 = async (recommendation) => {
		this.setState({isShowBackdrop: true})

		let body = {
			...recommendation,
			value: recommendation?.applicationDescription || recommendation?.value || ""
		}
		body.userId = this.props.user?.id;
		delete body.createdAt;
		delete body.updatedAt;
		delete body.id;
		delete body.orderId;
		delete body.applicationDescription;

		body.items = [...(body.items || [])].filter((t) => Boolean(t?.adminNote || t?.description || (t?.images || []).length > 0))
		body.items = await getApplicationItemsListDictionary(body.items || [], this.props.userRole);

		// Логика для каждой работы отдельно
		let newServiceItems = [];
		for (const item of (body?.items || [])) {
			let _imagesIds = [...(item?.images || [])].filter((t) => !Boolean(t.isNew)).map((t) => {
				return {id: t?.fileId || t?.id}
			});
			const _images = (await optimizationImageVideo(item?.images || [])).filter((t) => t.isNew);
			await Promise.all(_images.map(async (_image) => {
				const formData = new FormData();
				formData.append("file", _image.file);
				const fileId = await agent.post(`/orders/mechanic/${recommendation.orderId}/recommendations/${recommendation.id}/upload`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}).then((res) => {
					return res.data?.fileId || null
				}).catch((err) => {
					return null
				})
				if (!fileId) {
					Notification({
						message: "Ошибка добавление одно из файлов",
						type: notificationTypes.error
					})
				}
				_imagesIds.push({
					id: fileId
				})
			}));
			let matchingId = null;
			if (item?.is_create_connection) {
				const body = {
					searchPhrase: item?.description,
					matchingPhrase: item?.code?.name,
					codeId: item?.code?.id
				};
				matchingId = await agent.post('/dictionary/set-matching', body).then((res) => res?.data?.id).catch(() => null);
			}
			newServiceItems.push({
				...item,
				images: _imagesIds,
				code_id: matchingId || item?.code_id
			})
		}
		body.items = newServiceItems;

		const response = await agent.put(`/orders/mechanic/${recommendation.orderId}/recommendations/${recommendation.id}`, body).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		if (response.error) {
			this.setState({isShowBackdrop: false})

			Notification({
				message: response.error?.data?.details || "Ошибка сервера",
				type: notificationTypes.error
			})

			return
		}

		// Логика для общих файлов работы
		if (body.files && body.files.length > 0) {
			const files = (await optimizationImageVideo(body.files)).filter((t) => t.isNew);
			await Promise.all(files.map(async (file) => {
				const formData = new FormData();
				formData.append("file", file.file);
				const response = await agent.post(`/orders/mechanic/${recommendation.orderId}/recommendations/${recommendation.id}/upload`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}).then((res) => {
					return res.data
				}).catch((err) => {
					return {error: err.response}
				})
			}));
		}


		await this.getApplication();
		this.refDialogCreateWork.current.close();
		this.refDialogWorkManager.current.close();
		this.refDialogRecomendationManager.current.close();
		this.setState({isShowBackdrop: false})
		Notification({
			message: "Рекомендация успешно обновлена",
			type: notificationTypes.success
		})
	}
	openEditRecommendationV2 = async (form = {}, isSave = false) => {
		if (!isSave) {
			const initForm = {
				id: form.id,
				orderId: form.orderId,
				items: form?.items || [],
				files: form.pictures,
				admin_note: form?.admin_note || "",
				value: form.value
			};

			const { userRole } = this.props;
			const isAdminConsultant = Boolean(userRole === "consultant" || userRole === "admin");
			if (isAdminConsultant) {
				this.refDialogRecomendationManager.current.open({
					initForm: {...initForm},
					onSubmit: this.openEditRecommendationV2.bind(this),
					onDeleteWorkFile: this.deleteRecommendationFile.bind(this),
					isRecommendation: true
				})

				return
			}

			this.refDialogCreateWork.current.open({
				initForm: {...initForm},
				onSubmit: this.editRecommendationV2.bind(this),
				onDeleteWorkFile: this.deleteRecommendationFile.bind(this),
				isRecommendation: true
			})

			return
		}

		let body = {
			id: form.id,
			value: form.value,
			orderId: form.orderId,
			"items": form.items || [],
			"files": form.files || [],
			"admin_note": form?.admin_note || "",
			"description": form.applicationDescription,
			"cost": Number.parseFloat(form.applicationPrice),
			"estimates": (form.partsList || []).map((t) => {
				return {
					"description": t.name,
					"partsCost": Number.parseFloat(t.price)
				}
			})
		}
		await this.editRecommendationV2(body)
	}
	deleteRecommendationFile = async (fileId, recommendationId, callback, isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Вы точно хотите удалить файл?`,
				acceptButtonTitle: "Да, удалить",
				acceptButtonAction: this.deleteRecommendationFile.bind(this, fileId, recommendationId, callback, true),
			});

			return null;
		}

		this.setState({isShowBackdrop: true});

		const response = await agent.delete(`/orders/mechanic/${this.state.applicationId}/recommendations/${recommendationId}/upload/${fileId}`)
			.then(res => res.data)
			.catch(err => {
				return {error: err.response}
			});

		if (response.error) {
			this.setState({isShowBackdrop: false})

			Notification({
				message: response.error?.data?.details || "Ошибка сервера",
				type: notificationTypes.error
			});

			return null;
		}

		await callback();
		await this.getApplication();
		this.setState({isShowBackdrop: false})
		Notification({
			message: "Файл успешно удален",
			type: notificationTypes.success
		});
	};
	deleteRecommendation = async (recommendationId, isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Вы точно хотите удалить рекомендацию?`,
				acceptButtonTitle: "Да, удалить",
				acceptButtonAction: this.deleteRecommendation.bind(this, recommendationId, true),
			});

			return null;
		}

		this.setState({isShowBackdrop: true});

		const response = await agent.delete(`/orders/mechanic/${this.state.applicationId}/recommendations/${recommendationId}`)
			.then(res => res.data)
			.catch(err => {
				return {
					error: err.response,
				};
			});

		if (response.error) {
			this.setState({isShowBackdrop: false})

			Notification({
				message: response.error?.data?.details || "Ошибка сервера",
				type: notificationTypes.error
			});

			return null;
		}

		await this.getApplication();
		this.setState({isShowBackdrop: false})
		Notification({
			message: "Рекомендация успешно удалена",
			type: notificationTypes.success
		});
	};

	// Логика работы по ссылке
	generateLink = async () => {
		if (this.state.application?.externalId) {
			const url = [process.env.REACT_APP_CLIENT_URL_PATH, this.state.application?.externalId].join("");

			clipboardWriteText(url);
			Notification({
				message: "Ссылка успешно скопирована",
				type: notificationTypes.success,
			})

			return
		}

		this.setState({isShowBackdrop: true})

		const response = await agent.post(`/orders/mechanic/${this.state.applicationId}/publish`).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		if (response.error) {
			this.setState({isShowBackdrop: false})

			Notification({
				type: notificationTypes.error,
				message: response.error?.data?.details || "Ошибка сервера"
			})

			return
		}

		await this.getApplication();
		this.setState({isShowBackdrop: false});

		Notification({
			message: "Ссылка успешно сгенерирована",
			type: notificationTypes.success
		})
	}

	openDialogImagesVideos = (files, activeIndex) => {
		this.refDialogImagesVideos.current.open({
			files,
			activeIndex
		})
	}
	openDialogImagesMessage = ({ files, index, message }) => {
		this.refDialogImagesMessage.current.open({ files, index, message });
	}


	changeMileage = async (newVal) => {
		await this.setState({isShowBackdrop: true});

		let body = {...this.state.order};
		body.mileage = newVal || '';
		body.orderServices = (body.orderServices || []).map(item => {
			delete item.id;
			delete item.orderId;
			delete item.createdAt;
			delete item.updatedAt;
			delete item.updated_at;
			delete item.confirmedAt;
			return item
		});
		body.recommendationsForClient = (body.recommendationsForClient || []).map(item => {
			delete item.id;
			delete item.orderId;
			delete item.createdAt;
			delete item.updated_at;
			delete item.confirmedAt;
			return item
		});

		const res = await agent.put(`/orders/mechanic/${this.state.applicationId}`, body).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (res?.error) {
			await this.setState({isShowBackdrop: false});
			Notification({
				message: res.error?.data?.details || "Ошибка сервера"
			})
			return
		}

		await this.getApplication();
		await this.setState({isShowBackdrop: false});
		Notification({
			message: "Пробег успешно изменен",
			type: notificationTypes.success
		})
	}

	// Изменение статуса заказа
	changeOrderStatus = async (newStatus) => {
		await this.setState({isShowBackdrop: true});

		let body = {...this.state.order};
		body.status = newStatus || '';
		body.orderServices = (body.orderServices || []).map(item => {
			delete item.id;
			delete item.orderId;
			delete item.createdAt;
			delete item.updatedAt;
			delete item.updated_at;
			delete item.confirmedAt;
			return item
		});
		body.recommendationsForClient = (body.recommendationsForClient || []).map(item => {
			delete item.id;
			delete item.orderId;
			delete item.createdAt;
			delete item.updated_at;
			delete item.confirmedAt;
			return item
		});

		const res = await agent.put(`/orders/mechanic/${this.state.applicationId}`, body).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (res?.error) {
			await this.setState({isShowBackdrop: false});
			Notification({
				message: res.error?.data?.details || "Ошибка сервера"
			})
			return
		}

		await this.getApplication();
		await this.setState({isShowBackdrop: false});
		Notification({
			message: "Статус заказа успешно изменен",
			type: notificationTypes.success
		});
	}

	// Замарозка аккаунта
	startHoldApplication = async (isConfirm) => {
		if (this.state?.application?.status === "HOLD") {
			await this.endHoldApplication(false);
			return
		}
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				message: `Вы действительно хотите заблокировать заказ?`,
				acceptButtonTitle: 'Да',
				cancelButtonTitle: 'Нет',
				acceptButtonAction: this.startHoldApplication.bind(this, true)
			});
			return
		}

		await this.setState({ isShowBackdrop: true });
		await this.changeOrderStatus('HOLD');
		await this.setState({ isShowBackdrop: false });
	};
	endHoldApplication = async (isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				message: `Вы действительно хотите разблокировать заказ?`,
				acceptButtonTitle: 'Да',
				cancelButtonTitle: 'Нет',
				acceptButtonAction: this.endHoldApplication.bind(this, true)
			});
			return
		}
		await this.setState({ isShowBackdrop: true });
		await this.changeOrderStatus('IN_WORK');
		await this.setState({ isShowBackdrop: false });
	};
	_isDisabledHoldButton = () => {
		const { application } = this.state;
		const { user } = this.props;
		const { status, statuses } = application || {};
		const userId = user?.id;

		if (!status || status !== "HOLD") {
			return false
		}

		const _status = [...(statuses || [])].filter((t) => t.value === "HOLD").pop();
		if (_status?.user_id !== userId && status === "HOLD") {
			return true
		}

		return false
	}

	_disabledEditApplication = () => {
		const { userRole } = this.props;
		const { application } = this.state;
		const status = application.status;

		if (status === "HOLD") {
			return true
		}
		if (['EXECUTED', 'HOLD'].includes(status) && userRole === "mechanic") {
			return true
		}
		return false
	}

	render() {
		const {
			userRole,
			classes
		} = this.props;
		const {
			application,

			isLoad,
			isFail,
			isConsultant,
			isFullDisabled,
			isShowBackdrop,
			isAddClientButtonDisabled,
		} = this.state;
		const isDiagnostics = Boolean(userRole === "diagnosis");
		const isAdminConsultant = Boolean(userRole === "consultant" || userRole === "admin");

		return (
			<>

				<VisibleContent visible={Boolean(!isLoad && !isFail)}>

					<Box mb={1}>
						<Grid container alignItems="center" justifyContent="space-between" spacing={1}>
							<Grid item container xs={12} spacing={1} lg="auto">
								<VisibleContent visible={Boolean(!isAdminConsultant)}>
									<Grid item xs={12} lg="auto">
										<Button
											variant="contained"
											fullWidth
											disabled={['APPROVE', 'WAITING_FOR_SPARE_PARTS', 'REST', 'EXECUTED', 'CANCELED', 'CONFIRMED', 'PROCESS', 'COMPLETED'].includes(application.status) || isFullDisabled}
											onClick={this.createServices.bind(this, null)}
										>
											Добавить работу
										</Button>
									</Grid>
									<Grid item xs={12} lg="auto">
										<Button
											variant="contained"
											fullWidth
											disabled={['APPROVE', 'WAITING_FOR_SPARE_PARTS', 'REST', 'EXECUTED', 'CANCELED', 'CONFIRMED', 'PROCESS', 'COMPLETED'].includes(application.status) || isFullDisabled}
											onClick={this.createRecommendationV2.bind(this, null)}
										>
											Добавить рекомендацию
										</Button>
									</Grid>
								</VisibleContent>
								<VisibleContent visible={Boolean(isAdminConsultant)}>
									<Grid item xs={12} lg="auto">
										<Button
											variant="contained"
											fullWidth
											disabled={['APPROVE', 'WAITING_FOR_SPARE_PARTS', 'REST', 'EXECUTED', 'CANCELED', 'CONFIRMED', 'PROCESS', 'COMPLETED'].includes(application.status) || isFullDisabled}
											onClick={this.orderSynchronization}
										>
											Синхронизировать
										</Button>
									</Grid>
								</VisibleContent>
							</Grid>

							<Grid item container xs={12} spacing={1} lg="auto">
								{Boolean(userRole === 'consultant' || userRole === 'admin') && (
									<Grid item xs={12} lg="auto">
										<Button
											variant="contained"
											fullWidth
											disabled={!application.client || Boolean(isFullDisabled && !isAdminConsultant)}
											onClick={this.generateLink.bind(this, null)}
										>
											{Boolean(application?.externalId) ? "Скопировать ссылку" : "Сгенерировать ссылку"}
										</Button>
									</Grid>
								)}
								{Boolean(isConsultant) && (
									<Grid item xs={12} lg="auto">
										<Button
											fullWidth
											variant="contained"
											disabled={this._isDisabledHoldButton()}
											onClick={this.startHoldApplication.bind(this, false)}
										>
											{Boolean(application?.status === "HOLD") ? "UNLOCK" : "LOCK"}
										</Button>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Box>

					<Box mb={1} className={classes.user}>
						<ApplicationInfoComponent
							application={application}
							isDiagnostics={isDiagnostics}
							isFullDisabled={isFullDisabled}
							onChangeOrderStatus={this.changeOrderStatus}
						/>
					</Box>

					<VisibleContent visible={!isDiagnostics && application.client}>
						<Box mb={1} className={classes.user}>
							<UserInfoComponent
								vin={application?.vin}
								info={application?.info}
								client={application.client}
								mileage={application?.mileage || ''}
								reasonForContacting={application.reasonForContacting}

								onChangeMileage={this.changeMileage}
							/>
						</Box>
					</VisibleContent>

					<VisibleContent visible={!isDiagnostics && !application.client}>
						<Box mb={1}>
							<Button
								variant="contained"
								fullWidth
								disabled={isAddClientButtonDisabled || isFullDisabled}
								onClick={this.addClient}
							>Поиск клиента</Button>
						</Box>
					</VisibleContent>

					<VisibleContent visible={!isDiagnostics}>
						<Box mb={1} className={classes.user}>
							<TransportInfoComponent
								application={application}
								isFullDisabled={isFullDisabled}
								onUpdateApplication={this.getApplication}
								onChangeMileage={this.changeMileage}
							/>
						</Box>
					</VisibleContent>

					<Box mb={1} className={classes.user}>
						<DiagnosticsInfo
							application={application}
							isFullDisabled={isFullDisabled}
							onUpdateApplication={this.getApplication}
						/>
					</Box>

					<VisibleContent visible={!isDiagnostics}>
						<Box className={classes.rowContent}>
							<Box className={classes.root}>
								<WorkListComponent
									data={application?.orderServices || []}
									disabledEdit={this._disabledEditApplication()}
									hideButtonCreate={isAdminConsultant}

									onOpenCreateService={this.createServices}
									onEdit={this.editServices}
									onOpenEdit={this.openEditServices}
									onOpenDialogImagesVideos={this.openDialogImagesVideos}
									onOpenDialogImagesMessage={this.openDialogImagesMessage}
									onDeleteWork={this.deleteWork}
									isFilesLoading={this.state.isFilesLoading}
								/>
							</Box>
							<Box className={classes.root}>
								<RecommendationListComponent
									data={application?.recommendationsForClient || []}
									applicationStatus={application.status}
									disabledEdit={this._disabledEditApplication()}
									hideButtonCreate={isAdminConsultant}

									onEdit={this.editRecommendation}
									onOpenCreateRecommendation={this.createRecommendationV2}
									onOpenEdit={this.openEditRecommendationV2}
									onOpenDialogImagesVideos={this.openDialogImagesVideos}
									onOpenDialogImagesMessage={this.openDialogImagesMessage}
									onDeleteRecommendation={this.deleteRecommendation}
									isFilesLoading={this.state.isFilesLoading}
								/>
							</Box>
						</Box>
					</VisibleContent>
				</VisibleContent>
				<VisibleContent visible={Boolean(isLoad)}>
					<Box className={classes.loading}>
						<img src={require("../../../assets/svg/loading/loading.svg").default}/>
					</Box>
				</VisibleContent>
				<VisibleContent visible={Boolean(isFail)}>
					<Box className={classes.error}>
						Заказ не найден
					</Box>
				</VisibleContent>

				<DialogConfirmation ref={this.refDialogConfirmation}/>
				<DialogImagesVideos ref={this.refDialogImagesVideos}/>

				<DialogWorkManager ref={this.refDialogWorkManager}/>
				<DialogCreateWorkComponent ref={this.refDialogCreateWork}/>

				{/* Этот компонент для редактирование работ и рекомендаций от админа и консультанта */}
				<DialogRecomendationManager ref={this.refDialogRecomendationManager}/>

				<DialogCreateRecommendation ref={this.refDialogCreateRecommendation} userRole={userRole}/>
				<DialogImagesMessage ref={this.refDialogImagesMessage}/>

				<Backdrop open={isShowBackdrop}>
					<CircularProgress/>
				</Backdrop>

				<VisibleContent visible={Boolean(application?.status === 'HOLD')}>
					<Box className={classes.bottomInformation}>
						Заявка заблокирована
					</Box>
				</VisibleContent>
			</>
		);
	}
}

const VisibleContent = React.memo(({visible, children}) => {
	if (!visible) {
		return null
	}

	return children || null
})

const styles = {
	root: {
		width: "100%",
		padding: 20,
		boxSizing: "border-box",
		backgroundColor: "white",
		borderRadius: 14,
		border: "1px solid #F0F0F0",

		"@media(max-width: 1199px)": {
			padding: 0,
			borderRadius: 0,
			border: "none",
			backgroundColor: "transparent",
		}
	},
	user: {
		padding: 20,
		boxSizing: "border-box",
		backgroundColor: "white",
		borderRadius: 14,
		border: "1px solid #F0F0F0",

		"@media(max-width: 1199px)": {
			padding: 12
		}
	},
	rowContent: {
		display: "flex",
		gap: "8px",

		"@media(max-width: 1199px)": {
			flexDirection: "column",
		}
	},

	loading: {
		height: 100,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",

		backgroundColor: "white",
		borderRadius: 14,
		border: "1px solid #F0F0F0",

		"& img": {
			maxHeight: 80
		}
	},
	error: {
		fontSize: 20,
		lineHeight: "24px",
		textAlign: "center",
		padding: 20,
		boxSizing: "border-box",
		backgroundColor: "white",
		borderRadius: 14,
		border: "1px solid #F0F0F0",
	},

	bottomInformation: {
		position: "fixed",
		left: 8, right: 8, bottom: 8,
		borderRadius: 14,
		padding: 20,
		textAlign: "center",
		backgroundColor: "white",
		boxShadow: "0 2px 6px -2px #00000057",

		fontSize: 18,
		lineHeight: 1,
		fontWeight: "600"
	},
}
Application = withStyles(styles)(Application)

export default Application
